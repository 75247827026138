import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export class ApiError extends Error {
  constructor(
    public status: number,
    public message: string,
    public data?: any,
    public code?: string
  ) {
    super(message);
    this.name = 'ApiError';
  }
}

class ApiClient {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.setupInterceptors();
  }

  private setupInterceptors(): void {
    // Request interceptor
    this.client.interceptors.request.use(
      (config) => {
        // Get token from localStorage or your auth store
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Response interceptor
    this.client.interceptors.response.use(
      (response: AxiosResponse) => response.data,
      (error: AxiosError) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const status = error.response.status;
          const data = error.response.data as any;
          const message = data?.message || error.message;

          // Handle specific status codes
          switch (status) {
            case 401:
              // Handle unauthorized access
              localStorage.removeItem('token');
              window.location.href = '/login';
              break;
            case 403:
              // Handle forbidden access
              break;
            case 404:
              // Handle not found
              break;
            case 422:
              // Handle validation errors
              break;
            case 500:
              // Handle server errors
              break;
          }

          throw new ApiError(status, message, data, data?.code);
        } else if (error.request) {
          // The request was made but no response was received
          throw new ApiError(0, 'Network Error: No response received');
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new ApiError(0, error.message || 'Unknown Error');
        }
      }
    );
  }

  // Generic request method
  private async request<T>(
    method: string,
    url: string,
    data?: any,
    config?: any
  ): Promise<T> {
    try {
      const response = await this.client.request({
        method,
        url,
        data,
        ...config,
      });
      return response as T;
    } catch (error) {
      if (error instanceof ApiError) {
        throw error;
      }
      throw new ApiError(0, 'Unknown Error');
    }
  }

  // HTTP method wrappers
  public async get<T>(url: string, config?: any): Promise<T> {
    return this.request<T>('GET', url, undefined, config);
  }

  public async post<T>(url: string, data?: any, config?: any): Promise<T> {
    return this.request<T>('POST', url, data, config);
  }

  public async put<T>(url: string, data?: any, config?: any): Promise<T> {
    return this.request<T>('PUT', url, data, config);
  }

  public async patch<T>(url: string, data?: any, config?: any): Promise<T> {
    return this.request<T>('PATCH', url, data, config);
  }

  public async delete<T>(url: string, config?: any): Promise<T> {
    return this.request<T>('DELETE', url, undefined, config);
  }
}

export const apiClient = new ApiClient();
