import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { AppError, ErrorCode } from '../types/error';
import { apiClient } from '../api/apiClient';

export interface User {
  _id: string;
  email: string;
  phone: string;
  photo: string;
  fullname: string;

  role: 'admin' | 'user' | 'moderator';
  active: boolean;
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface AuthResponse {
  status: string;
  token: string;
  user: User;
}

interface AuthState {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  error: AppError | null;

  // Actions
  login: (credentials: LoginCredentials) => Promise<void>;
  logout: () => Promise<void>;
  // getCurrentUser: () => Promise<void>;
  clearError: () => void;
  setUser: (user: User) => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    persist(
      (set) => ({
        user: null,
        token: null,
        isAuthenticated: false,
        loading: false,
        error: null,

        login: async (credentials) => {
          try {
            set({ loading: true, error: null });
            const response = await apiClient.post<AuthResponse>('/api/v1/users/login', credentials);
            const { user, token } = response;

            // Check if user has admin role
            if (user.role !== 'admin') {
              throw new AppError(
                ErrorCode.UNAUTHORIZED,
                'Access denied. Only administrators are allowed to login.'
              );
            }

            localStorage.setItem('token', token);
            set({
              user,
              token,
              isAuthenticated: true,
              loading: false,
              error: null,
            });
          } catch (error) {
            console.error('Login failed:', error);
            const appError = error instanceof AppError
              ? error
              : new AppError(ErrorCode.UNKNOWN_ERROR, 'Login failed');
            set({ loading: false, error: appError });
            throw appError;
          }
        },

        logout: async () => {
          try {
            await apiClient.post('/auth/logout');
          } finally {
            localStorage.removeItem('token');
            set({
              user: null,
              token: null,
              isAuthenticated: false,
              error: null,
            });
          }
        },

        // getCurrentUser: async () => {
        //   try {
        //     set({ loading: true, error: null });
        //     const response = await apiClient.get<AuthResponse>('/auth/me');
        //     const { user } = response.data;

        //     // Check if user has admin role
        //     if (user.role !== 'admin') {
        //       throw new AppError(
        //         ErrorCode.UNAUTHORIZED,
        //         'Access denied. Only administrators are allowed to access this application.'
        //       );
        //     }

        //     set({ user, isAuthenticated: true, loading: false });
        //   } catch (error) {
        //     localStorage.removeItem('token'); // Clear token if user is not admin
        //     const appError = error instanceof AppError
        //       ? error
        //       : new AppError(ErrorCode.UNKNOWN_ERROR, 'Failed to fetch user');
        //     set({
        //       loading: false,
        //       error: appError,
        //       user: null,
        //       token: null,
        //       isAuthenticated: false
        //     });
        //     throw appError;
        //   }
        // },

        clearError: () => {
          set({ error: null });
        },

        setUser: (user) => {
          // Only set the user if they are an admin
          if (user.role === 'admin') {
            set({ user, isAuthenticated: true });
          } else {
            set({
              user: null,
              isAuthenticated: false,
              error: new AppError(
                ErrorCode.UNAUTHORIZED,
                'Access denied. Only administrators are allowed.'
              )
            });
          }
        },
      }),
      {
        name: 'auth-storage',
        partialize: (state) => ({
          token: state.token,
          user: state.user,
          isAuthenticated: state.isAuthenticated,
        }),
      }
    )
  )
);


export default useAuthStore;