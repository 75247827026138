import React from 'react';
import { ConfigProvider } from 'antd';
import { NextUIProvider } from '@nextui-org/react';
import { AuthProvider } from './contexts/AuthContext';
import AppRoutes from './routes';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

const App: React.FC = () => {
  return (
    <NextUIProvider>
      <ConfigProvider>
        <ErrorBoundary>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ErrorBoundary>
      </ConfigProvider>
    </NextUIProvider>
  );
};

export default App;
