import { Spin } from 'antd';
import React, { lazy, Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ProtectedLayout from './components/layouts/ProtectedLayout';

// Lazy load components
const LoginForm = lazy(() => import('./components/auth/LoginForm'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const UnauthorizedPage = lazy(() => import('./components/UnauthorizedPage'));

// Bhandara Components
const AccDeleteRequest = lazy(() => import('./components/accdeleterequest/AccDeleteRequest'));
const BannerUpdate = lazy(() => import('./components/Bhandaracomponents/banner/BannerUpdate'));
const Community = lazy(() => import('./components/Bhandaracomponents/community/communityWrapper'));
const RefralCredit = lazy(() => import('./components/Bhandaracomponents/refralCredit/RefralCredit'));
const PostsLists = lazy(() => import('./components/Bhandaracomponents/users/postListWrapper'));
const UsersList = lazy(() => import('./components/Bhandaracomponents/users/UserListWrapper'));
const Wallet = lazy(() => import('./components/Bhandaracomponents/wallet/Wallet'));
const AnnouncementPg = lazy(() => import('./pages/announcement/AnnouncementPg'));
// const UserList = lazy(() => import('./components/Bhandaracomponents/users/UserList'));
const BhandaraList = lazy(() => import('./components/Bhandaracomponents/bhandaras/BhandaraList'));

const LoadingFallback = () => (
  <div className="h-screen flex items-center justify-center">
    <Spin size="large" />
  </div>
);

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<LoginForm />} />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />

          {/* Protected Routes - Only accessible by admins */}
          <Route element={<ProtectedLayout />}>
            <Route
              path="/"
              element={
                <Dashboard />
              }
            />

            {/* Admin Features */}
            <Route
              path="/users"
              element={
                <UsersList />
              }
            />
            <Route
              path="/posts"
              element={
                <PostsLists />
              }
            />
            <Route
              path="/community"
              element={
                <Community />
              }
            />
            <Route
              path="/wallet"
              element={
                <Wallet />
              }
            />
            <Route
              path="/banner"
              element={
                <BannerUpdate />
              }
            />
            <Route
              path="/refral-credit"
              element={
                <RefralCredit />
              }
            />
            <Route
              path="/announcement"
              element={
                <AnnouncementPg />
              }
            />
            <Route
              path="/delete-requests"
              element={
                <AccDeleteRequest />
              }
            />
            <Route
              path="/bhandaras"
              element={
                <BhandaraList />
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
