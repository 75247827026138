import React, { createContext, useContext } from 'react';
import useAuthStore from '../store/useAuthStore';
import { AppError } from '../types/error';

interface AuthContextType {
  isLoading: boolean;
  isAuthenticated: boolean;
  error: AppError | null;
  isAdmin: boolean;
  isModerator: boolean;
  // checkAuth: () => Promise<void>;
  clearError: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    user,
    loading: isLoading,
    error,
    isAuthenticated: isAuthenticatedFromStore,
    clearError,
  } = useAuthStore();

  // const checkAuth = async () => {
  //   if (!isAuthenticatedFromStore && localStorage.getItem('token')) {
  //     await getCurrentUser();
  //   }
  // };

  // useEffect(() => {
  //   checkAuth();
  // }, []);

  const value = {
    isLoading,
    isAuthenticated: isAuthenticatedFromStore,
    error,
    isAdmin: user?.role.toLowerCase() === 'admin',
    isModerator: user?.role.toLowerCase() === 'moderator',
    clearError,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
