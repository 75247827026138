export enum ErrorCode {
  // Authentication Errors
  UNAUTHORIZED = 'UNAUTHORIZED',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  
  // Authorization Errors
  FORBIDDEN = 'FORBIDDEN',
  INSUFFICIENT_PERMISSIONS = 'INSUFFICIENT_PERMISSIONS',
  ACCESS_DENIED = 'ACCESS_DENIED',
  ADMIN_ONLY = 'ADMIN_ONLY',
  
  // Resource Errors
  NOT_FOUND = 'NOT_FOUND',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  CONFLICT = 'CONFLICT',
  
  // Validation Errors
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  INVALID_INPUT = 'INVALID_INPUT',
  
  // Server Errors
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  
  // Network Errors
  NETWORK_ERROR = 'NETWORK_ERROR',
  TIMEOUT = 'TIMEOUT',
  
  // Unknown Error
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export interface ErrorDetails {
  field?: string;
  message: string;
}

export interface ApiErrorResponse {
  code: ErrorCode;
  message: string;
  details?: ErrorDetails[];
  status: number;
}

export class AppError extends Error {
  constructor(
    public code: ErrorCode,
    public message: string,
    public details?: ErrorDetails[],
    public status: number = 500
  ) {
    super(message);
    this.name = 'AppError';
  }

  static fromApiError(error: ApiErrorResponse): AppError {
    return new AppError(error.code, error.message, error.details, error.status);
  }

  static isAppError(error: any): error is AppError {
    return error instanceof AppError;
  }
}

export const getErrorMessage = (error: unknown): string => {
  if (AppError.isAppError(error)) {
    return error.message;
  }
  if (error instanceof Error) {
    return error.message;
  }
  return 'An unknown error occurred';
};

export const getErrorCode = (error: unknown): ErrorCode => {
  if (AppError.isAppError(error)) {
    return error.code;
  }
  return ErrorCode.UNKNOWN_ERROR;
};

export const isNetworkError = (error: unknown): boolean => {
  const code = getErrorCode(error);
  return code === ErrorCode.NETWORK_ERROR || code === ErrorCode.TIMEOUT;
};
