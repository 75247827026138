import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import useAuthStore from '@/store/useAuthStore';
import { useLayoutStore } from '@/store/useLayoutStore';
import cn from 'classnames';
import { Bell, LogOut, Menu } from 'lucide-react';

export function Header() {
  const { user, logout } = useAuthStore();
  const { toggleSidebar } = useLayoutStore();

  return (
    <header className="sticky top-0 z-40 w-full border-b bg-white">
      <div className={cn(
        "flex h-14 items-center justify-between",
        "px-2 md:px-4",
        "transition-all duration-300 ease-in-out"
      )}>
        <div className="flex items-center gap-2 md:gap-4">
          <Button
            variant="ghost"
            size="icon"
            onClick={toggleSidebar}
            className="hover:bg-transparent"
          >
            <Menu className="h-5 w-5" />
            <span className="sr-only">Toggle sidebar</span>
          </Button>


          <h1 className="text-sm font-medium">
            Bhandara Dashboard
          </h1>

        </div>

        <div className="flex items-center gap-2 md:gap-4">
          <Button
            variant="ghost"
            size="icon"
            className="relative hover:bg-gray-100"
          >
            <Bell className="h-5 w-5" />
          </Button>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                <Avatar className="h-8 w-8">
                  <AvatarImage src={user?.photo || undefined} />
                  <AvatarFallback>
                    <img src="/images/defaultUser.jpg" alt="Default User" className="h-8 w-8" />
                  </AvatarFallback>
                </Avatar>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 bg-white" align="end" forceMount>
              <DropdownMenuLabel className="font-normal">
                <div className="flex flex-col space-y-1">
                  {/* <p className="text-sm font-medium leading-none truncate">
                    {user?.displayName}
                  </p> */}
                  <p className="text-xs leading-none text-muted-foreground truncate">
                    {user?.email}
                  </p>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuItem
                onClick={logout}
                className="text-red-600 focus:text-red-600 cursor-pointer"
              >
                <LogOut className="mr-2 h-4 w-4" />
                <span>Log out</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
}
