import { useState, useEffect } from 'react';
import { BREAKPOINTS } from '@/constants/breakpoints';

type BreakpointKey = keyof typeof BREAKPOINTS;
type BreakpointOperation = 'up' | 'down' | 'between' | 'only';

interface UseBreakpointOptions {
  start?: BreakpointKey;
  end?: BreakpointKey;
  operation?: BreakpointOperation;
}

/**
 * A hook for responsive design that works with predefined breakpoints
 * @param options Configuration options for the breakpoint check
 * @returns boolean indicating if the current viewport matches the breakpoint criteria
 * 
 * @example
 * // Check if screen is smaller than TABLET
 * const isMobile = useBreakpoint({ end: 'TABLET', operation: 'down' });
 * 
 * // Check if screen is larger than MOBILE
 * const isDesktop = useBreakpoint({ start: 'MOBILE', operation: 'up' });
 * 
 * // Check if screen is between MOBILE and LAPTOP
 * const isTablet = useBreakpoint({ 
 *   start: 'MOBILE', 
 *   end: 'LAPTOP', 
 *   operation: 'between' 
 * });
 * 
 * // Check if screen matches exactly the TABLET breakpoint
 * const isTablet = useBreakpoint({ start: 'TABLET', operation: 'only' });
 */
export const useBreakpoint = ({
  start = 'MOBILE',
  end,
  operation = 'down'
}: UseBreakpointOptions = {}) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const checkBreakpoint = () => {
      const width = window.innerWidth;
      
      switch (operation) {
        case 'down':
          setMatches(width < BREAKPOINTS[start]);
          break;
        case 'up':
          setMatches(width >= BREAKPOINTS[start]);
          break;
        case 'between':
          if (!end) throw new Error('End breakpoint is required for "between" operation');
          setMatches(
            width >= BREAKPOINTS[start] && 
            width < BREAKPOINTS[end]
          );
          break;
        case 'only':
          const nextBreakpoint = Object.entries(BREAKPOINTS).find(
            ([_, value]) => value > BREAKPOINTS[start]
          );
          setMatches(
            width >= BREAKPOINTS[start] && 
            (!nextBreakpoint || width < nextBreakpoint[1])
          );
          break;
      }
    };

    // Check on mount and add listener
    checkBreakpoint();
    window.addEventListener('resize', checkBreakpoint);

    // Cleanup
    return () => window.removeEventListener('resize', checkBreakpoint);
  }, [start, end, operation]);

  return matches;
};

// Convenience hooks for common breakpoint checks
export const useIsMobile = () => 
  useBreakpoint({ end: 'TABLET', operation: 'down' });

export const useIsTablet = () => 
  useBreakpoint({ 
    start: 'MOBILE', 
    end: 'LAPTOP', 
    operation: 'between' 
  });

export const useIsDesktop = () => 
  useBreakpoint({ start: 'LAPTOP', operation: 'up' });
